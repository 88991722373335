<template>
  <v-card class="modal-create mailers-modal">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Создание рассылки</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-row>
          <v-col
              cols="12"
          >
            <v-text-field
                class="evi-text-field"
                color="#44D370"
                v-model="mailer.name"
                placeholder="Название рассылки"
                :rules="nameRules"
            >
              <template v-slot:label>
                Название рассылки
                <span class="important">*</span>
              </template>
            </v-text-field>
            <v-radio-group
                v-model="mailer.is_regular"
                class="evi-radio-button-group"
            >
              <template v-slot:label>
                Тип рассылки
                <span class="important">*</span>
              </template>
              <div class="d-flex align-center">
                <v-radio
                    v-for="item in types"
                    :label="item.name"
                    :value="item.value"
                    :key="item.value"
                    class="mb-0 mr-6 evi-radio"
                    color="#45BF6A"
                />
              </div>
            </v-radio-group>
          </v-col>
          <v-col cols="12"
                 v-if="mailer.is_regular">
            <v-autocomplete
                class="evi-autocomplete"
                color="#44D370"
                :items="daysOfWeek"
                v-model="mailer.days_of_week"
                item-text="name"
                item-value="id"
                clearable
                item-color="green"
                multiple
            >
              <template v-slot:label>
                Дни рассылки
              </template>
              <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" v-if="!mailer.is_regular">
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFormated"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    label="Дата"
                    color="#44D370"
                    class="evi-text-field"
                >
                  <template v-slot:label>
                    Дата
                    <span class="important">*</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                  v-model="mailer.send_date"
                  @input="dateMenu = false"
                  color="#44D370"
                  first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col :cols="!mailer.is_regular ? 6 : 12">
            <v-menu
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="mailer.send_time"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    label="Время"
                    color="#44D370"
                    class="evi-text-field"
                >
                  <template v-slot:label>
                    Время
                    <span class="important">*</span>
                  </template>
                </v-text-field>
              </template>
              <v-time-picker
                  v-model="mailer.send_time"
                  @input="timeMenu = false"
                  color="#44D370"
                  format="24hr"
                  use-seconds
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                class="evi-autocomplete mb-5"
                color="#44D370"
                v-model="mailer.integration_id"
                :items="localIntegrations"
                item-text="name"
                item-value="id"
                :loading="integrationLoading"
                @focus="loadIntegrations()"
                :search-input.sync="integrationSearch"
                @input.native="searchIntegration(integrationSearch)"
                clearable
                item-color="green"
            >
              <template v-slot:label>
                Рассылать по интеграции
              </template>
              <template v-slot:append-item>
                <div v-intersect="integrationIntersect"/>
              </template>
              <template v-slot:append>
                      <span class="evi-select__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
              </template>
              <template v-slot:selection="{item}">
                <div class="v-select__selection v-select__selection--comma">
                  <img width="20" class="mr-1"
                       :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:item="{item}">
                <v-list-item-title>
                  <img width="20" class="mr-1"
                       :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                  {{ item.name }}
                </v-list-item-title>
              </template>
            </v-autocomplete>
            <v-autocomplete
                class="evi-autocomplete mb-5"
                color="#44D370"
                :items="statuses"
                v-model="mailer.client_statuses"
                item-text="name"
                item-value="id"
                clearable
                item-color="green"
                multiple
                @focus="getStatuses()"
            >
              <template v-slot:label>
                Рассылать по статусам клиента
              </template>
              <template v-slot:append>
                  <span class="evi-autocomplete__arrow">
                      <v-icon>mdi-chevron-down</v-icon>
                  </span>
              </template>
              <template v-slot:selection="{item}">
                <div class="modal-create__color-select">
                  <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:item="{item}">
                <div class="modal-create__color-select">
                  <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                  {{ item.name }}
                </div>
              </template>
            </v-autocomplete>
            <v-autocomplete
                class="evi-autocomplete mb-5"
                color="#44D370"
                :items="tags"
                v-model="mailer.tags"
                item-text="name"
                item-value="id"
                clearable
                item-color="green"
                @focus="getTags"
                multiple
            >
              <template v-slot:label>
                Рассылать по тегам
              </template>
              <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
              </template>
              <template v-slot:selection="{item}">
                <div class="evi-autocomplete__tag mr-2 mt-2"
                     :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:item="{item}">
                <div class="evi-autocomplete__tag"
                     :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                  {{ item.name }}
                </div>
              </template>
            </v-autocomplete>
            <v-autocomplete
                class="evi-autocomplete mb-5"
                color="#44D370"
                :items="tags"
                v-model="mailer.tags_ignore"
                item-text="name"
                item-value="id"
                clearable
                item-color="green"
                @focus="getTags"
                multiple
            >
              <template v-slot:label>
                Не рассылать, если есть теги
              </template>
              <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
              </template>
              <template v-slot:selection="{item}">
                <div class="evi-autocomplete__tag mr-2 mt-2"
                     :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:item="{item}">
                <div class="evi-autocomplete__tag"
                     :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                  {{ item.name }}
                </div>
              </template>
            </v-autocomplete>
            <v-textarea
                no-resize
                class="evi-textarea"
                color="#44D370"
                v-model="mailer.message"
                ref="message"
            >
              <template v-slot:label>
                Сообщение
              </template>
              <template v-slot:append>
                <EmojiPicker @pushEmoji="pushEmoji"/>
              </template>
            </v-textarea>
            <v-file-input
                class="evi-text-field"
                color="#44D370"
                v-model="files"
                label="Прикрепить файлы"
                accept="image/*, .doc, .docx, .pdf, .htm, .html, .txt, .rtf, .xml, .xlsx,.xls"
                multiple
                chips
            />
            <div class="evi-toolbar__night mb-11" :class="{'active': mailer.notify}">
              <v-switch
                  label="Уведомление о выполнении"
                  v-model="mailer.notify"
              ></v-switch>
            </div>
            <v-autocomplete
                v-if="mailer.notify"
                class="evi-autocomplete"
                color="#44D370"
                :items="users"
                v-model="mailer.users"
                item-text="name"
                item-value="id"
                :loading="userLoading"
                @focus="loadUsers"
                :search-input.sync="userSearch"
                @input.native="searchUser(userSearch)"
                clearable
                item-color="green"
                multiple
            >
              <template v-slot:label>
                Кому отправлять уведомление
              </template>
              <template v-slot:append-item>
                <div v-intersect="userIntersect"/>
              </template>
              <template v-slot:item="{item}">
                <v-avatar
                    color="#FFFFFF"
                    size="30"
                    class="user-page__avatar mr-3"
                >
                  <img
                      :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
                <div class="v-list-item__title">{{ item.name }}</div>
              </template>
              <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {IntegrationAutocompleteRequestsMixin} from "@/mixins/autocomplete/IntegrationAutocompleteRequestsMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {Weekdays} from "@/mixins/Weekdays";
import EmojiPicker from "../EmojiPicker";
import {docsTypes} from "@/utils/attachmentsTypes";

export default {
  name: "CreateMailer",
  mixins: [IntegrationAutocompleteRequestsMixin, UserAutocompleteRequestsMixin, Weekdays],
  components: {EmojiPicker},
  data: () => ({
    valid: true,
    mailer: {
      name: '',
      is_regular: false,
      integration_id: null,
      send_date: null,
      send_time: '',
      message: '',
      days_of_week: [],
      tags: [],
      tags_ignore: [],
      client_statuses: [],
      users: [],
      notify: false,
    },
    files: [],
    dateMenu: false,
    timeMenu: false,
    types: [
      {
        name: 'Отложенная',
        value: false,
      },
      {
        name: 'Регулярная',
        value: true
      },
    ],
    nameRules: [(v) => !!v || 'Введите название рассылки'],
  }),
  computed: {
    dateFormated() {
      return this.mailer.send_date ? this.$moment(this.mailer.send_date).format('DD.MM.YYYY') : null;
    },
    ...mapState("tags", ["tags",]),
    ...mapState("client-statuses", ["statuses",]),
    localIntegrations() {
      return this.integrations.filter(i => i.type !== 'form');
    }
  },
  methods: {
    ...mapActions({
      createMailer: 'mailers/createMailer',
      getTags: 'tags/getItemsBy',
      getStatuses: 'client-statuses/getStatusesBy',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        let formData = new FormData();

        for (let key in this.mailer) {
          if (key !== 'send_date' && key !== 'days_of_week' && key !== 'users') {
            if (typeof this.mailer[key] === 'boolean') {
              formData.append(key, this.mailer[key] ? '1' : '0');
            } else {
              formData.append(key, this.mailer[key]);
            }
          }
        }

        if (!this.mailer.is_regular) {
          formData.append('send_date', this.mailer.send_date);
        } else {
          this.mailer.days_of_week.forEach((item) => {
            formData.append('days_of_week[]', item);
          });
        }

        if (this.mailer.notify) {
          this.mailer.users.forEach((item) => {
            formData.append('users[]', item);
          });
        }

        this.files.forEach((item) => {
          if (docsTypes.some(type => type === item.type)) {
            formData.append('docs[]', item);
          } else {
            formData.append('images[]', item);
          }
        });

        this.createMailer(formData).then(() => {
          this.closeDialog();
          this.$emit('update');
        });
      }
    },
    pushEmoji(e) {
      this.mailer.message += (e);
      this.$refs.message.focus();
    },
    closeDialog() {
      this.$emit('close');
      this.mailer.name = '';
      this.mailer.message = '';
      this.mailer.send_date = null;
      this.mailer.send_time = null;
      this.mailer.is_regular = false;
      this.mailer.notify = false;
      this.mailer.integration_id = null;
      this.mailer.days_of_week = [];
      this.mailer.users = [];
      this.mailer.tags = [];
      this.mailer.tags_ignore = [];
      this.files = [];
      this.$refs.form.resetValidation();
    }
  },
  watch: {
    'mailer.is_regular': {
      handler: function () {
        this.mailer.send_date = null;
        this.mailer.days_of_week = [];
      },
    }
  }
}
</script>
