import {mapActions, mapState} from "vuex";

export var IntegrationAutocompleteRequestsMixin = {
    data: () => ({
        integrationSearch: "",
        timerId: null,
        page: 0,
        perPage: 20,
        integrationLoading: false,
    }),
    computed: {
        ...mapState("integrations", ["integrations"]),
    },
    methods: {
        ...mapActions({
            getIntegrations: 'integrations/getIntegrationsBy',
            addIntegrations: 'integrations/addIntegrationsBy',
        }),
        getIntegrationsBy (search, type) {
            this.page = 1;
            this.integrationLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            if (type) {
                args['filter[type]'] = type;
            }
            this.getIntegrations(args).then(() => {
                this.integrationLoading = false;
            });
        },
        addIntegrationsBy (search, type) {
            this.page++;
            this.integrationLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            if (type) {
                args['filter[type]'] = type;
            }
            this.addIntegrations(args).then(() => {
                this.integrationLoading = false;
            });
        },
        loadIntegrations (type = null) {
            this.getIntegrationsBy("", type);
        },
        integrationIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addIntegrationsBy(this.integrationSearch);
            }
        },
        searchIntegration (val, type = null) {
            if (val) {
                this.page = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getIntegrationsBy(val, type);
                }, 500);
            }
        },
    },
    mounted () {
        this.loadIntegrations();
    },
};
