<template>
<div>
  <es-table class="mailers" :headers="headers" :items="mailers" @update="getItemPages" :filter="filters" sortable>
    <div class="mailers__actions" v-if="hasRight('clients.manage')">
      <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="createMailerModal = true">
        Создать рассылку
      </v-btn>
    </div>
    <v-dialog
        v-model="createMailerModal"
        max-width="612px"
    >
      <CreateMailer @update="update" @close="createMailerModal = false"/>
    </v-dialog>
    <template slot="sort-menu">
      <v-list dense class="settings-menu">
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title>
              <v-autocomplete
                  class="evi-autocomplete"
                  color="#44D370"
                  :items="types"
                  v-model="filters['filter[is_regular]']"
                  item-text="name"
                  item-value="value"
                  clearable
                  item-color="green"
              >
                <template v-slot:label>
                  Фильтровать по типу
                </template>
                <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                  class="evi-autocomplete"
                  color="#44D370"
                  :items="tags"
                  v-model="filters['filter[tags.id]']"
                  item-text="name"
                  item-value="id"
                  clearable
                  item-color="green"
                  @focus="getTags"
                  multiple
              >
                <template v-slot:label>
                  Фильтровать по тегам
                </template>
                <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
                </template>
                <template v-slot:selection="{item}">
                  <div class="evi-autocomplete__tag mr-2 mt-2"
                       :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:item="{item}">
                  <div class="evi-autocomplete__tag"
                       :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                    {{ item.name }}
                  </div>
                </template>
              </v-autocomplete>
              <v-autocomplete
                  class="evi-autocomplete"
                  color="#44D370"
                  :items="tags"
                  v-model="filters['filter[tagsIgnore.id]']"
                  item-text="name"
                  item-value="id"
                  clearable
                  item-color="green"
                  @focus="getTags"
                  multiple
              >
                <template v-slot:label>
                  Фильтровать по игнор-тегам
                </template>
                <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
                </template>
                <template v-slot:selection="{item}">
                  <div class="evi-autocomplete__tag mr-2 mt-2"
                       :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:item="{item}">
                  <div class="evi-autocomplete__tag"
                       :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                    {{ item.name }}
                  </div>
                </template>
              </v-autocomplete>
              <v-autocomplete
                  class="evi-autocomplete"
                  color="#44D370"
                  v-model="filters['filter[integration.id]']"
                  :items="localIntegrations"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :loading="integrationLoading"
                  @focus="loadIntegrations()"
                  :search-input.sync="integrationSearch"
                  @input.native="searchIntegration(integrationSearch)"
                  clearable
                  item-color="green"
              >
                <template v-slot:label>
                  Фильтровать по интеграции
                </template>
                <template v-slot:append-item>
                  <div v-intersect="integrationIntersect"/>
                </template>
                <template v-slot:append>
                      <span class="evi-select__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
                </template>
                <template v-slot:selection="{item}">
                  <div class="v-select__selection v-select__selection--comma">
                    <img width="20" class="mr-1"
                         :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:item="{item}">
                  <v-list-item-title>
                    <img width="20" class="mr-1"
                         :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                    {{ item.name }}
                  </v-list-item-title>
                </template>
              </v-autocomplete>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </es-table>
</div>
</template>

<script>
import {hasRight} from '@/utils/access/hasRight';
import {mapActions, mapState} from "vuex";
import CreateMailer from "@/components/mailers/CreateMailer";
import {IntegrationAutocompleteRequestsMixin} from "@/mixins/autocomplete/IntegrationAutocompleteRequestsMixin";
import {RouteFilterPaginationMixin} from "../../mixins/RouteFilterPaginationMixin";

export default {
  name: "Mailers",
  components: {CreateMailer},
  mixins: [IntegrationAutocompleteRequestsMixin, RouteFilterPaginationMixin],
  data: () => ({
    createMailerModal: false,
    types: [
      {
        name: 'Отложенная',
        value: false,
      },
      {
        name: 'Регулярная',
        value: true
      },
    ],
    headers: [
      {
        text: 'Название',
        value: 'name',
      },
      {
        text: 'Тип рассылки',
        value: 'is_regular',
      },
      {
        text: 'Интеграция',
        value: 'integration.name',
      },
      {
        text: 'Дата рассылки',
        value: 'send_date',
      },
      {
        text: 'Дни рассылки',
        value: 'days_of_week',
      },
      {
        text: 'Время рассылки',
        value: 'send_time',
      },
      {
        text: 'Уведомления',
        value: 'notify',
      },
    ],
    intFilter: null,
    tagFilter: null,
    tagIgnoreFilter: null,
    regularMailerFilter: null,
    filters: {
      'filter[integration.id]': undefined,
      'filter[tags.id]': undefined,
      'filter[tagsIgnore.id]': undefined,
      'filter[is_regular]': undefined,
    },
  }),
  computed: {
    ...mapState("mailers", ["mailers",]),
    ...mapState("tags", ["tags",]),
    localIntegrations() {
      return this.integrations.filter(i => i.type !== 'form');
    },
  },
  methods: {
    ...mapActions({
      getItemPages: 'mailers/getMailersPages',
      getTags: 'tags/getItemsBy',
    }),
    update () {
      this.getItemPages({
        per_page: 10,
        page: 1,
      });
    },
    hasRight: hasRight,
  },
  mounted() {
    this.getTags();
    this.routeFiltersInit(this.filters);
  },
  watch: {
    filters: {
      handler (val) {
        this.filterWatcher(val);
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
